import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "autopatcher-command-line-interface"
    }}>{`AutoPatcher Command line interface`}</h1>
    <p><em parentName="p">{`AutoPatcher CLI uses the authenticaton provided by the Auth0 service. When you use the CLI for the first time it will open the web page with the login form. You have to log in using your business (@nordcloud.com) email. If you do not have permissions to use the CLI please ask on the `}<strong parentName="em">{`#pat-autopatcher`}</strong>{` slack channel`}</em></p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <ol>
      <li parentName="ol">{`Download the latest version (`}<inlineCode parentName="li">{`v1.135.0`}</inlineCode>{`) of `}<inlineCode parentName="li">{`nc-autopatcher-cli`}</inlineCode>{` for your platform and architecture:`}</li>
    </ol>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ap-cli.s3.eu-west-1.amazonaws.com/nc-autopatcher-cli-v1.135.0-linux-prod-amd64"
        }}>{`Linux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ap-cli.s3.eu-west-1.amazonaws.com/nc-autopatcher-cli-v1.135.0-darwin-prod-amd64"
        }}>{`Mac OS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ap-cli.s3.eu-west-1.amazonaws.com/nc-autopatcher-cli-v1.135.0-darwin-prod-arm64"
        }}>{`Mac OS with M1 processor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ap-cli.s3.eu-west-1.amazonaws.com/nc-autopatcher-cli-v1.135.0-windows-prod-amd64.exe"
        }}>{`Windows`}</a></li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`For Linux and MacOS:`}</li>
    </ol>
    <p>{`2.1. `}<inlineCode parentName="p">{`cp /path/to/downloaded/nc-autopatcher-cli-{VERSION}-{PLATFORM}-prod-{ARCH} ~/.local/bin/nc-autopatcher-cli`}</inlineCode></p>
    <p>{`2.2. `}<inlineCode parentName="p">{`chmod +x ~/.local/bin/nc-autopatcher-cli`}</inlineCode></p>
    <h2 {...{
      "id": "configuration"
    }}>{`Configuration`}</h2>
    <p>{`Run the following command to configure the CLI for the usage with your customer environment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli configure
`}</code></pre>
    <p>{`The list of all available customers (with auto-completion capability) will be shown.`}</p>
    <p>{`For more advanced configuration see `}<a parentName="p" {...{
        "href": "#advanced-configuration"
      }}>{`Advanced configuration`}</a>{`.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli COMMAND SUBCOMMAND [FLAGS]
`}</code></pre>
    <p><inlineCode parentName="p">{`COMMAND`}</inlineCode>{`s are divided into three categories:`}</p>
    <ol>
      <li parentName="ol">{`Commands for general API items access`}</li>
      <li parentName="ol">{`Resource-specific commands`}</li>
      <li parentName="ol">{`Other commands`}</li>
    </ol>
    <h3 {...{
      "id": "general-api-items-access"
    }}>{`General API items access`}</h3>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`get ITEM`}</inlineCode>{` - Get one item`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`list ITEMs`}</inlineCode>{` - Get all items of some type (note the plural form of ITEMs)`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`create ITEM`}</inlineCode>{` - Create an item`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`update ITEM`}</inlineCode>{` - Update an items`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`delete ITEM`}</inlineCode>{` - Delete an item`}</li>
    </ol>
    <p>{`An `}<inlineCode parentName="p">{`ITEM`}</inlineCode>{` can be one of:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`machine`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`event`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`plan`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`pipeline`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`notification_group`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`update_log`}</inlineCode></li>
    </ol>
    <p>{`Please note that not all general API item access commands can be used with all item types. Refer to the corresponding command's help to see valid combinations.`}</p>
    <p><inlineCode parentName="p">{`get`}</inlineCode>{`, `}<inlineCode parentName="p">{`update`}</inlineCode>{` and `}<inlineCode parentName="p">{`delete`}</inlineCode>{` require an ID of the item to be passed through `}<inlineCode parentName="p">{`--id`}</inlineCode>{` flag.`}</p>
    <p><inlineCode parentName="p">{`update`}</inlineCode>{` and `}<inlineCode parentName="p">{`create`}</inlineCode>{` commands require `}<inlineCode parentName="p">{`--file`}</inlineCode>{` flag which defines a JSON file with data to populate an item with.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`nc-autopatcher-cli get event --id 43e60f1e-50c2-468f-afc0-bcdcedf26faa`}</inlineCode>{`. Example output:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    "created_at": "2018-12-08T00:07:39+00:00",
    "description": "description",
    "hash": "74f5a89d-3003-4b45-b7ca-63dfc0ccb7a7",
    "id": "43e60f1e-50c2-468f-afc0-bcdcedf26faa",
    "machines": [],
    "name": "NAME-1",
    "customer_id": "1234",
    "parallel": 1,
    "patching_status": "DONE",
    "plan_id": "plan_with_spaces_1",
    "updated_at": "2018-12-08T00:07:39+00:00",
    "window_end_time": "2018-12-08T02:07:39+00:00",
    "window_start_time": "2018-12-08T00:07:39+00:00"
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`nc-autopatcher-cli list machines`}</inlineCode>{`. Example output:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`[
    {
        "access": {
            "ssm_machine_id": "mi-111111"
        },
        "allow_reboot": true,
        "created_at": "2018-12-07T10:47:27+00:00",
        "id": "m1",
        "name": "name",
        "customer_id": "1234",
        "plans": [],
        "updated_at": "2018-12-07T10:47:27+00:00"
    }
]
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`nc-autopatcher-cli create plan --file plan.json`}</inlineCode>{`. Example output:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    "id": "67042200-46ae-4d8a-a8de-e7666ceb5c53"
}

`}</code></pre>
        <p parentName="li"><inlineCode parentName="p">{`plan.json`}</inlineCode>{` contents:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    "customer_id": "1234",
    "name": "NAME",
    "description": "description",
    "cron_window_start": "0 3 * * *",
    "cron_window_end": "0 4 * * *",
    "parallel": 1,
    "machines": [
        {
            "id": "m1",
            "order": 3
        }
    ]
}
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "resource-specific-commands"
    }}>{`Resource-specific commands`}</h3>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`event SUBCOMMAND`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`plan SUBCOMMAND`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`pipeline SUBCOMMAND`}</inlineCode></li>
    </ol>
    <p>{`Each resource-specific command has different set of `}<inlineCode parentName="p">{`SUBCOMMAND`}</inlineCode>{`s. Refer to the help for each command for details.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`nc-autopatcher-cli plan force_update --id 67042200-46ae-4d8a-a8de-e7666ceb5c53`}</inlineCode>{`. Example output:`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    "id": "dbbacd79-43b7-464e-801b-bf68e419aede"
}
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`nc-autopatcher-cli event generate_report --id 00b3d2d9-2086-45ac-9809-866cb8a21da1`}</inlineCode>{`. Example output:`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    "message": "Successfully started report generation.",
    "report_id": "00b3d2d9-2086-45ac-9809-866cb8a21da1"
}
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`nc-autopatcher-cli event get_report --id 00b3d2d9-2086-45ac-9809-866cb8a21da1`}</inlineCode>{`. Example output:`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    "id": "00b3d2d9-2086-45ac-9809-866cb8a21da1",
    "name": "NAME",
    "not_patched_machines": {},
    "patched_machines": {
        "m2": {
            "installed": [],
            "removed": [],
            "status": "Success",
            "updated": [
                "python3-software-properties_0.96.24.32.6_all.deb",
                "python3-update-manager_1%3a18.04.11.8_all.deb",
                "software-properties-common_0.96.24.32.6_all.deb",
                "update-manager-core_1%3a18.04.11.8_all.deb"
            ]
        }
    },
    "patching_status": "DONE",
    "plan_id": "67042200-46ae-4d8a-a8de-e7666ceb5c53",
    "window_end_time": "2018-12-12T04:00:00+00:00",
    "window_start_time": "2018-12-12T03:00:00+00:00"
}
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "complex-use-cases"
    }}>{`Complex use-cases`}</h3>
    <h4 {...{
      "id": "defining-a-dynamic-plan"
    }}>{`Defining a dynamic plan`}</h4>
    <p>{`In order to define scanning conditions more granularly for a dynamic plan, the user needs to define the
`}<inlineCode parentName="p">{`tag_condition`}</inlineCode>{` field in the dynamic plan instead of the legacy `}<inlineCode parentName="p">{`tag_list`}</inlineCode>{`.
Below is an example of how to define a sample plan which will patch the machines which have `}<strong parentName="p">{`both`}</strong>{`
`}<inlineCode parentName="p">{`autopatcher-enable=true`}</inlineCode>{` and `}<inlineCode parentName="p">{`patching-stage=dev-1`}</inlineCode>{` (or `}<inlineCode parentName="p">{`patching-stage=dev-2`}</inlineCode>{`) tags attached:`}</p>
    <ol>
      <li parentName="ol">{`Create a file named `}<inlineCode parentName="li">{`plan.json`}</inlineCode>{` with the following content (when using this JSON payload for a real use-case please
make sure to review all the fields carefully as this is just a sample):`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "cron_window_start": "0 0 31 2 *",
  "window_length": 2,
  "name": "Example dynamic plan",
  "machines_tag": {
    "iam_role_list": ["arn:aws:iam::000000000000:role/NordcloudAutoPatcherSSMServiceRole"],
    "regions": ["eu-west-1"],
    "tag_condition": {
      "expression": "autopatcher-enable=true AND patching-stage=dev-1,dev-2"
    }
  }
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Run the following CLI command (for instructions on how to download and prepare the CLI for usage please visit `}<a parentName="li" {...{
          "href": "/cli"
        }}>{`this page`}</a>{`):`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli create plan --file plan.json
`}</code></pre>
    <p>{`When the AWS account being scanned contains the following EC2 instances`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tags`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`dev-2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`QA`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`Production`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`autopatcher-enable`}</inlineCode>{` = `}<inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine-8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patching-stage`}</inlineCode>{` = `}<inlineCode parentName="td">{`Production`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`only `}<inlineCode parentName="p">{`machine-1`}</inlineCode>{` and `}<inlineCode parentName="p">{`machine-2`}</inlineCode>{` will be patched accordingly to the defined logical expression.`}</p>
    <h5 {...{
      "id": "detailed-description-of-the-tag_condition-structure"
    }}>{`Detailed description of the `}<inlineCode parentName="h5">{`tag_condition`}</inlineCode>{` structure`}</h5>
    <p>{`The `}<inlineCode parentName="p">{`tag_condition`}</inlineCode>{` structure in the `}<inlineCode parentName="p">{`machines_tag`}</inlineCode>{` has two fields:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`expression`}</inlineCode>{` `}<strong parentName="li">{`[REQUIRED]`}</strong>{` - a logical expression defining tag keys and values that the scanned machines should have.
The case-insensitive `}<inlineCode parentName="li">{`AND`}</inlineCode>{`, `}<inlineCode parentName="li">{`OR`}</inlineCode>{` and `}<inlineCode parentName="li">{`NOT`}</inlineCode>{` operators (and their respective short forms `}<inlineCode parentName="li">{`&`}</inlineCode>{`, `}<inlineCode parentName="li">{`|`}</inlineCode>{` and `}<inlineCode parentName="li">{`!`}</inlineCode>{`) are allowed
as well as arbitrary levels of parentheses. Each tag definition should have the `}<inlineCode parentName="li">{`<KEY>=<VALUES_LIST>`}</inlineCode>{` form
(note that the `}<inlineCode parentName="li">{`=`}</inlineCode>{` character `}<strong parentName="li">{`cannot`}</strong>{` be surrounded with white space). `}<inlineCode parentName="li">{`<KEY>`}</inlineCode>{` is a single token whereas
`}<inlineCode parentName="li">{`<VALUES_LIST>`}</inlineCode>{` is a comma-separated list of tokens. Each token can consist of alphanumeric and
`}<strong parentName="li"><inlineCode parentName="strong">{`_`}</inlineCode></strong>{` (underscore), `}<strong parentName="li"><inlineCode parentName="strong">{`-`}</inlineCode></strong>{` (hyphen), `}<strong parentName="li"><inlineCode parentName="strong">{`.`}</inlineCode></strong>{` (period), `}<strong parentName="li"><inlineCode parentName="strong">{`:`}</inlineCode></strong>{` (colon) characters only.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`placeholders`}</inlineCode>{` `}<em parentName="li">{`[OPTIONAL]`}</em>{` - if there is a need to specify a tag key or value with unsupported characters a special
placeholder token (starting with a colon (`}<inlineCode parentName="li">{`:`}</inlineCode>{`)) can be used. The values of all placeholders used in the expression
should be defined in the `}<inlineCode parentName="li">{`placeholders`}</inlineCode>{` field, otherwise an error will occur.`}</li>
    </ul>
    <p>{`Example tag condition with placeholders:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "tag_condition": {
    "expression": "key1=group-a AND (key2=:v1 OR !:k3=:v2,v4,:v5)",
    "placeholders": [
      {"key": "v1", "value": "tag value with spaces"},
      {"key": "k3", "value": "key -%^&"},
      {"key": "v2", "value": "special chars !@#$%^&*()"},
      {"key": "v5", "value": "a b c"}
    ]
  }
}
`}</code></pre>
    <h3 {...{
      "id": "other-commands"
    }}>{`Other commands`}</h3>
    <h4 {...{
      "id": "configure"
    }}>{`Configure`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli configure
`}</code></pre>
    <p>{`This command is used to fill in the configuration file for the AutoPatcher CLI with values obtained by asking the user interactively.`}</p>
    <h4 {...{
      "id": "bash-completion"
    }}>{`Bash completion`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`. <(nc-autopatcher-cli bash_completion)
`}</code></pre>
    <p>{`The above command sets up the current Bash session to be able to TAB-complete `}<inlineCode parentName="p">{`nc-autopatcher-cli`}</inlineCode>{` commands. It can be added to `}<inlineCode parentName="p">{`~/.bashrc`}</inlineCode>{` to be available in each shell session automatically.`}</p>
    <h4 {...{
      "id": "help-command"
    }}>{`Help command`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli help [COMMAND [SUBCOMMAND]]
`}</code></pre>
    <p>{`Prints help about some command and its subcommands or, if the command is omitted, general usage.`}</p>
    <h2 {...{
      "id": "advanced-configuration"
    }}>{`Advanced configuration`}</h2>
    <p>{`There are several global configuration options available:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`customer-id`}</inlineCode>{` `}<strong parentName="li">{`required`}</strong>{` - An ID of the customer with whose resources you will be working`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`api-host`}</inlineCode>{` `}<strong parentName="li">{`required`}</strong>{` - AutoPatcher GraphQL API endpoint`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`oauth-domain`}</inlineCode>{` `}<strong parentName="li">{`required`}</strong>{` - Address of the Auth0 login domain used for the authentication`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`oauth-client-id`}</inlineCode>{` `}<strong parentName="li">{`required`}</strong>{` - Id of the Auth0 clitn used for the authentication`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`api-key`}</inlineCode>{` `}<strong parentName="li">{`optional`}</strong>{` - API key used for alternative authentication, takes precedence over Auth0`}</li>
    </ul>
    <p>{`Below are the three different ways of configuring the CLI`}</p>
    <h3 {...{
      "id": "1-configuration-file"
    }}>{`1. Configuration file`}</h3>
    <p>{`Configuration file is in YAML format with options defined as top-level keys. Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`api-host:
  "https://api.autopatcher.nordcloudapp.com"
oauth-client-id:
  "4BgImeXVBzK2fqNxig6bh1xfxE4Fy9C1"
oauth-domain:
  "login.nordcloudapp.com"
`}</code></pre>
    <p>{`Default config file location is `}<inlineCode parentName="p">{`$HOME/.nc-autopatcher-cli.yml`}</inlineCode>{`.`}</p>
    <p>{`Custom config file can be specified with `}<inlineCode parentName="p">{`--config`}</inlineCode>{` flag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli --config FILE [commands] ...
`}</code></pre>
    <h3 {...{
      "id": "2-environment-variables-take-precedence-over-config-file"
    }}>{`2. Environment variables (take precedence over config file)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment variable`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`api-host`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AP_API_HOST`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`oauth-client-id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AP_OAUTH_CLIENT_ID`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`oauth-domain`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AP_OAUTH_DOMAIN`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`customer-id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AP_CUSTOMER_ID`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "3-command-line-flags-take-precedence-over-config-file-and-environment-variables"
    }}>{`3. Command line flags (take precedence over config file and environment variables)`}</h3>
    <p>{`In order to pass custom value for a configuration option on command line add a flag named as the option with two dashes prepended`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli --api-host <CUSTOM_HOST> [COMMANDS] ...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      